<template>
	<p class="title-p" style="width: 100%;">用户管理</p>
	<el-row :gutter="20" style="height: calc(100% - 31px)">
		<!-- <el-col :span="5">
			<div class="item-car">
				<div class="item-title-line">
					用户列表
				</div>
				<div class="ul-tree-box">
					
					<div class="ul-tree-item" v-for="(item,index) in treeList" :key="index"
						:class="{'active':nowTreeCheck === item.year}"
						@click="checkTreeUl(item)"
					>
						<p class="truncate">{{item.year}}</p>
						<div class="tree-icon-box">
							<a style="cursor: pointer;" @click.stop="editGrade(item)">
							  <el-icon><Edit /></el-icon>
							</a>
							<el-popconfirm confirm-button-text="确定" cancel-button-text="取消"
							               icon-color="red" title="确定删除吗?" @confirm="removeTree(item)">
							  <template #reference>
							    <a style="margin-left: 8px;cursor: pointer;">
							      <el-icon style="color: #F56C6C;">
							        <delete />
							      </el-icon>
							    </a>
							  </template>
							</el-popconfirm>
						</div>
					</div>
					
				</div>
				 <div class="add-class" @click="addGrade">
				 	&#43;新增用户
				 </div>
				 
			</div>
		</el-col> -->
		<el-col :span="24">
			<div class="ctent" style="position: relative">
				<div class="item-title-line">
					用户管理
				</div>
			  <div class="title-tool department-tool">
			
			    <div style="display: flex;">
			      <div class="tool-item" style="width: 400px;">
			        <div class="tool-label">
			          搜索：
			        </div>
			        <el-input v-model="search" placeholder="输入班级、老师搜索" style="width: 100%;"/>
			      </div>
			
			      <div class="tool-item" style="color: #ffffff;">
			        <el-button color="#2968D4" type="primary" @click="selectBtn">查 询</el-button>
			        <el-button @click="resetBtn">重置</el-button>
			      </div>
			    </div>
				
				<div class="add-class" style="margin-top: 0;margin-right: 15px;" @click="addClass">
					新增用户
				</div>
			
			  </div>
			  <div class="department-table">
			    <el-table :data="clasTableData" style="width: 100%" :row-style="{height:'65px'}">
			      <el-table-column prop="username" label="用户名称" align="center"/>
			      <el-table-column prop="account" label="账号" align="center" show-overflow-tooltip/>
			      <el-table-column prop="update_time" label="修改时间" align="center"/>
			      <el-table-column label="操作">
			        <template #default="scope">
			          <div style="display: flex; align-items: center">
			            <button class="el-button el-button--text el-button--small" type="button"
			                    @click="editClass(scope.row)" >
			              <span style="color: #409eff;">修改</span>
			            </button>
			           <el-popconfirm title="确定要删除吗?" @confirm="delClass(scope.row)">
			              <template #reference>
			                <button class="el-button el-button--text el-button--small" type="button">
			                  <span style="color: #f56c6c;">删除</span>
			                </button>
			              </template>
			            </el-popconfirm>
			          </div>
			        </template>
			      </el-table-column>
			      <!-- <el-table-column prop="job_number" label="工号"  align="center"/> -->
			    </el-table>
			  </div>
			  <!-- 分页 -->
			  <div class="pagination-box">
			    <el-pagination
			        v-model:currentPage="nowPage"
			        v-model:page-size="pageLimit"
			        layout="total,prev, pager, next, jumper"
			        :total="tableTotal"
			        @current-change="handleCurrentChange"
			    />
			  </div>
			</div>
		</el-col>
	</el-row>
	<!-- 用户 -->
	<el-dialog v-model="dialogFormVisibleNj" :title="gradeTitle" @closed="closedCloseGrade">
	    <el-form :model="form">
	      <el-form-item label="用户名称" :label-width="80">
	        <el-input v-model="gradeName" autocomplete="off" />
	      </el-form-item>
		  <el-form-item label="年级主任" :label-width="80">
		    <el-input v-model="teacherName" autocomplete="off" />
		  </el-form-item>
	    </el-form>
	    <template #footer>
	      <span class="dialog-footer">
	        <el-button @click="dialogFormVisibleNj = false">取消</el-button>
	        <el-button type="primary" @click="addGradeForm">
	          确认
	        </el-button>
	      </span>
	    </template>
	  </el-dialog>
	  <!-- 新增班级 -->
	  <el-dialog v-model="dialogFormVisibleClass" :title="classTitle" @closed="closedCloseClass">
	      <el-form :model="classForm">
	        <el-form-item label="用户名称" :label-width="80">
	          <el-input v-model="classForm.name" autocomplete="off" />
	        </el-form-item>
			<el-form-item label="登录账号" :label-width="80">
			  <el-input v-model="classForm.account" autocomplete="off" />
			</el-form-item>
			<el-form-item label="登录密码" :label-width="80">
			  <el-input v-model="classForm.password" autocomplete="off" show-password type="password" />
			</el-form-item>
			<el-form-item label="备注" :label-width="80">
			  <el-input v-model="classForm.note" autocomplete="off" type="textarea" :rows="2"/>
			</el-form-item>
	      </el-form>
	      <template #footer>
	        <span class="dialog-footer">
	          <el-button @click="dialogFormVisibleClass = false">取消</el-button>
	          <el-button type="primary" @click="addClassForm">
	            确认
	          </el-button>
	        </span>
	      </template>
	    </el-dialog>
</template>

<script>
	import { ElMessage } from 'element-plus';
	import md5 from 'js-md5';
	export default {
	  name: "userManagement",
	  data() {
		  return {
			  userData:JSON.parse(sessionStorage.getItem('ucode')),
			  classTitle:'添加用户',
			  classForm:{
				  name:'',
				  account:'',
				  password:'',
				  note:''
			  },
			  clasTableData:[],
			  search:'',
			  gradeName:'',
			  teacherName:'',
			  gradeTitle:'添加用户',
			  dialogFormVisibleClass:false,
			  dialogFormVisibleNj:false,
			  nowPage:1,
			  pageLimit:9,
			  tableTotal:0,
			  treeList:[],
			  nowTreeCheck:'',
			  parent_id:0,
			  isAddGrade:true,
			  nowEditGrade :'',
			  nowEditClass:'',
			  isAddClass:true
		  }
	  },
	  methods:{
		  addClassForm(){
			  var password = md5(this.classForm.password);
			  if (this.isAddClass) {
				    //  user_id,username,password,account,note
			  	this.addUpdateFomr('',this.classForm.name,password,this.classForm.account,this.classForm.note)
			  }else{
				  this.addUpdateFomr(this.nowEditClass.user_id + '',this.classForm.name,password,this.classForm.account,this.classForm.note)
			  }
		  },
		  addGradeForm(){
			  if (this.isAddGrade) {
			  	this.addUpdateFomr('','0',this.gradeName,this.teacherName,this.gradeName,'','Grade')
			  }else{
				  this.addUpdateFomr(this.nowEditGrade.id,'0',this.gradeName,this.teacherName,this.gradeName,'','Grade')
			  }
		  },
		  delClass(data){
			  vaildInterface("wechat/user.php","delUser",{
			    keyword:'delUser',
			    user_id:data.user_id,
			  },"01").then((data)=>{
			    //数据返回成功
			    if(data.success){
			  			ElMessage.success(data.msg);
						// 班级列表
						this.getClassTableData();
			    }else{
						ElMessage.error(data.msg)
					}
		  }).catch((result=>{
			    //数据返回失败
			  }));
		  },
		  delGrade(id){
			  vaildInterface("wechat/api.php","delGradeClass",{
				keyword:'delGradeClass',
				id:id,
			  },"01").then((data)=>{
				//数据返回成功
				if(data.success){
						ElMessage.success(data.msg);
						this.getGradeTableData();
				}else{
						ElMessage.error(data.msg)
					}
		  }).catch((result=>{
					//数据返回失败
				  }));
		  },
		  addUpdateFomr(user_id,username,password,account,note){
			  vaildInterface("wechat/user.php","addUser",{
			    keyword:'addUser',
			    user_id:user_id,
			    username:username,
				password:password,
				account:account,
				note:note,
			  },"01").then((data)=>{
					  console.log(data);
			    //数据返回成功
			    if(data.success){
			  			ElMessage.success(data.msg);
						// 班级列表
						this.getClassTableData();
						this.dialogFormVisibleClass = false;
			    }else{
						ElMessage.error(data.msg)
					}
			  }).catch((result=>{
			    //数据返回失败
			  }));
		  },
		  closedCloseGrade(){
			  this.gradeName = '';
			  this.teacherName = '';
			  this.nowEditGrade = '';
		  },
		  closedCloseClass(){
			  this.classForm = {
					 name:'',
					 account:'',
					 password:'',
					 note:''
				};
				this.nowEditClass = '';
		  },
		  addClass(){
			  this.classTitle = '添加用户';
			  this.isAddClass = true;
			  this.dialogFormVisibleClass = true;
		  },
		  editClass(data){
			  this.classTitle = '修改用户';
			  this.isAddClass = false;
			  this.nowEditClass = data;
			  this.classForm = {
				  name:data.username,
				  account:data.account,
				  password:data.password,
				  note:data.note
			  };
			  this.dialogFormVisibleClass = true;
		  },
		  editGrade(item){
			  this.gradeTitle = '修改用户信息';
			  this.gradeName = item.year;
			  this.teacherName = item.teacher;
			  this.nowEditGrade = item;
			  this.isAddGrade = false;
			  this.dialogFormVisibleNj = true;
		  },
		  addGrade(){
			  this.gradeTitle = '添加用户信息';
			  this.isAddGrade = true;
			  this.dialogFormVisibleNj = true;
		  },
		  removeTree(data){
			  console.log(data);
			  this.delGrade(data.id);
		  },
		  checkTreeUl(data){
			  this.nowTreeCheck = data.year;
			  this.parent_id = data.id;
			  this.getClassTableData();
		  },
		  handleCurrentChange(val) {
		    this.nowPage = val;
		    // 班级列表
		    this.getClassTableData();
		  },
		  getClassTableData(){
			  vaildInterface("wechat/user.php","userList",{
			    keyword:'userList',
			    user_id:this.userData.user_id,
				limit:this.pageLimit.toString(),
				page:this.nowPage.toString(),
				search:this.search
			  },"01").then((data)=>{
				  console.log(data);
			    //数据返回成功
			    if(data.success){
					this.clasTableData = data.data.list;
					this.tableTotal = data.data.total;
			    }else{
					ElMessage.error(data.msg)
				}
			  }).catch((result=>{
			    //数据返回失败
			  }));
		  },
		  getGradeTableData(){
			  vaildInterface("wechat/api.php","getSchoolClass",{
			    keyword:'getSchoolClass',
			    child:'0',
			    parent_id:'0'
			  },"01").then((data)=>{
				  console.log(data);
			    //数据返回成功
			    if(data.success){
					this.treeList = data.data.list;
					this.nowTreeCheck = data.data.list[0].year;
					this.parent_id = data.data.list[0].id;
					// 班级列表
					this.getClassTableData();
					console.log(this.treeList);
			    }else{
					ElMessage.error(data.msg)
				}
			  }).catch((result=>{
			    //数据返回失败
			  }));
		  },
		  selectBtn(){
			  // 班级列表
			  this.getClassTableData();
		  },
		  resetBtn(){
			  this.search = ''
		  }
	  },
	  mounted() {
		  // 获取列表
	  	this.getClassTableData();
	  }
	  
	  }
</script>

<style scoped>
.item-car{
	background-color: #fff;
	border-radius: 3px;
	height: 100%;
	padding: 5px 8px;
	box-sizing: border-box;
	overflow-y: auto;
}
.item-title-line{
	font-weight: bold;
	color: #333333;
	border-bottom: #E7E7E7 1px solid;
	width: 100%;
	padding-bottom: 15px;
	padding-top: 10px;
}
.ul-tree-box{
	width: 100%;
	box-sizing: border-box;
	padding-top: 10px;
}
.ul-tree-item{
	width: 100%;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	box-sizing: border-box;
	padding: 0 10px;
	border-radius: 5px;
}
.ul-tree-item.active{
	background: #ECF2FE;
	color: #0052D9;
}
.truncate{
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.tree-icon-box{
	min-width: 45px;
	box-sizing: border-box;
	padding-top: 6px;
}
.add-class{
	margin-top: 10px;
	color: #0052D9;
	text-align: left;
	box-sizing: border-box;
	padding-left: 25px;
	cursor: pointer;
}
.ctent {
  width: 100%;
  margin: 0 auto;
  height: 100%;
  padding: 30px;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 10px;
      padding-top: 5px;
}
.title-tool {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tool-item {
  width: 26%;
  height: 48px;
  display: flex;
  align-items: center;
  margin-right: 50px;
}

.tool-label {
  width: 80px;
  min-width: 80px;
  color: #303133;
}
.department-table {
  width: 100%;
  height: calc(100% - 80px);
  overflow: auto;
  padding-top: 10px;
  box-sizing: border-box;
  position: relative;
}

.pagination-box {
  position: absolute;
  bottom: 20px;
  left: 50%;
  bottom: 17px;
  width: 100%;
  transform: translate(-50%, 10px);
  box-sizing: border-box;
  padding: 0 25px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>